<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Verification Portal</h1>
      <div class="flex justify-end items-end">
        <button
          @click="downloadCsv"
          class="bg-blue-600 py-3 px-6 rounded-md text-white"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center justify-between">
        <div class="w-1/2">
          <input
            type="text"
            class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
            name=""
            placeholder="Search business name"
            id=""
            v-model="search"
          />
          <button
            @click="searchBusinessName"
            class="inline-block uppercase shadow ml-3 bg-blue-800 text-white text-xs hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
          >
            Search
          </button>
          <button
            @click="clearSearchBusinessName"
            class="inline-block uppercase shadow ml-3 bg-red-800 text-white text-xs hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
          >
            Clear
          </button>
        </div>
      </div>
      <div class="flex bg-blue-50 p-5 mt-10">
        <div class="flex-2 inline-block time-filter">
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startDate"
          />
        </div>
        <div class="flex-2 inline-block time-filter">
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.endDate"
          />
        </div>
        <div class="flex mt-5">
          <button
            @click="fetchDatedRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearDateFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Reset
          </button>
          <!-- <button
              @click="downloadDatedCSV"
              class="block uppercase shadow bg-blue-800 hover:bg-blue-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
            >
              Download
            </button> -->
        </div>
      </div>
      <div class="block mr-3 mt-5 px-5">
        Filter By Contact Verification Type
      </div>
      <div class="flex bg-gray-50 px-5">
        <div class="block mt-2 mr-3">
          <multiselect
            :taggable="false"
            v-model="filterByContactType"
            :options="contactTypes"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Filter by Document Type"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single hidden"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.toString() }}</span
              ></template
            >
          </multiselect>
        </div>
        <div class="block">
          <button
            class="inline-block bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            @click="filterFunc"
          >
            Filter
          </button>
        </div>
        <div class="block">
          <button
            class="inline-block drop-shadow-2xl bg-yellow-200 text-dark py-2 px-3 mt-4 rounded-md mr-2"
            @click="clearFilterFunc"
            :disabled="filterByContactType == ''"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <div class="mt-5" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="15"
              style="background: #dee6f2"
            >
              Merchant Information
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Merchant Id
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Business Name
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Merchant Name
            </th>

            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Last KYC Update Date
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Email Verification
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Phone Verification
            </th>

            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Verification Code
            </th>
          </tr>
          <tr
            v-for="(document, i) in filterDocuments"
            :key="i"
            class="cursor-pointer"
            @click="
              $router.push({
                name: 'ViewVerification',
                params: {
                  id: document.businessId,
                },
              })
            "
          >
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.businessId }}
            </td>
            <td
              class="border whitespace-nowrap px-8 py-3 text-xs capitalize"
              style="white-space: initial"
            >
              {{ document.businessName || "N/A" | truncate(30, "...") }}
            </td>
            <td
              class="border whitespace-nowrap px-8 py-3 text-xs lowercase"
              style="white-space: initial"
            >
              {{ document.merchantName || "N/A" }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs">
              {{
                moment(document.updateDate).format("DD MMM YYYY - h:mm:ss a")
              }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.email.verificationReference || "N/A" }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.phone.verificationReference || "N/A" }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.verificationCode || "N/A" }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          :class="currentPage === 1 ? 'bg-blue-200' : 'bg-blue-900  '"
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          v-if="filterDocuments.length > 99"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      moment,
      documents: null,
      isLoading: false,
      search: "",
      isDisabled: true,
      currentPage: 1,
      statusFilterActive: false,
      dateFilterActive: false,
      dateData: null,
      searchActive: false,
      contactFilterActive: false,
      filterByContactType: "",
      contactTypes: ["EMAIL", "PHONE"],
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapGetters(["GET_ALL_VERIFICATIONS"]),
    filterDocuments() {
      let k =
        this.GET_ALL_VERIFICATIONS.data != undefined &&
        this.GET_ALL_VERIFICATIONS.data.items &&
        this.GET_ALL_VERIFICATIONS.data.items.filter(
          (document) =>
            document.businessName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            document.businessId
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            document.merchantName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            document.businessName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            document.verificationCode
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      return k;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    // console.log(this.documents);
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      creationDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.fetchVerifications();
  },
  methods: {
    async fetchVerifications() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage,
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        contactFilterActive: this.contactFilterActive,
        filterByContactType: this.filterByContactType,
      };
      try {
        let response = await this.$store.dispatch(
          "ALL_VERIFICATION_DOCUMENTS",
          payload
        );
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    resetDate() {
      const d = new Date();
      this.dateData = {
        startDate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
        endDate: new Date().toJSON().split("T")[0],
        creationDate: new Date().toJSON().split("T")[0],
        startTime: "00:00",
        endTime: this.moment(d).format("HH:mm"),
      };
    },
    async downloadCsv() {
      this.isLoading = true;
      let payload = {
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        contactFilterActive: this.contactFilterActive,
        filterByContactType: this.filterByContactType,
      };
      try {
        let response = await this.$store.dispatch(
          "ALL_VERIFICATION_DOWNLOAD_CSV",
          payload
        );
        this.isLoading = false;
        if (response.status) {
          window.open(response.data, "_blank");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let page = parseInt(currPage - 1, 10);
      let payload = {
        page,
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        contactFilterActive: this.contactFilterActive,
        filterByContactType: this.filterByContactType,
      };
      try {
        let res = await this.$store.dispatch(
          "ALL_VERIFICATION_DOCUMENTS",
          payload
        );
        if (res.status) {
          this.isLoading = false;
          this.currentPage -= 1;
          this.documents = res.data.items;
        }
      } catch (error) {}
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let page = parseInt(currPage + 1, 10);
      let payload = {
        page,
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        contactFilterActive: this.contactFilterActive,
        filterByContactType: this.filterByContactType,
      };
      try {
        let res = await this.$store.dispatch(
          "ALL_VERIFICATION_DOCUMENTS",
          payload
        );
        if (res.status) {
          this.isLoading = false;
          this.currentPage += 1;
        }
      } catch (error) {}
    },

    async searchBusinessName() {
      if (this.search !== "") {
        this.isLoading = true;
        this.searchActive = true;
        let payload = {
          statusFilterActive: this.statusFilterActive,
          dateFilterActive: this.dateFilterActive,
          status: this.activeStatus,
          date: this.dateData,
          searchActive: this.searchActive,
          search: this.search,
          contactFilterActive: this.contactFilterActive,
          filterByContactType: this.filterByContactType,
          page: 1,
        };
        let res = await this.$store.dispatch(
          "ALL_VERIFICATION_DOCUMENTS",
          payload
        );
        this.isLoading = false;
        if (!res.status) {
          alert(res.message);
          this.isLoading = false;
          this.fetchVerifications();
          this.search = "";
          // this.currentPage += 1;
        } else {
          this.isLoading = false;
        }
      }
    },

    async clearSearchBusinessName() {
      this.search = "";
      this.searchActive = false;
      this.currentPage = 1;
      this.fetchVerifications();
    },

    async fetchDatedRecords() {
      this.dateFilterActive = true;
      this.currentPage = 1;
      if (
        this.dateData.startDate > this.dateData.endDate ||
        this.dateData.startDate === this.dateData.endDate
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        let currPage = (this.currentPage = 1);

        let payload = {
          page: currPage,
          statusFilterActive: this.statusFilterActive,
          dateFilterActive: this.dateFilterActive,
          status: this.activeStatus,
          date: this.dateData,
          searchActive: this.searchActive,
          search: this.search,
          contactFilterActive: this.contactFilterActive,
          filterByContactType: this.filterByContactType,
        };
        try {
          this.isLoading = true;
          let res = await this.$store.dispatch(
            "ALL_VERIFICATION_DOCUMENTS",
            payload
          );

          this.isLoading = false;
          if (response.status) {
            this.isLoading = false;
          }
        } catch (error) {}
      }
    },
    clearDateFilter() {
      this.dateFilterActive = false;
      this.currentPage = 1;
      this.resetDate();
      this.fetchVerifications();
    },
    async clearFilterFunc() {
      this.contactFilterActive = false;
      this.filterByContactType = "";
      this.fetchVerifications();
      this.currentPage = 1;
    },
    async filterFunc() {
      this.currentPage = 1;
      this.contactFilterActive = true;
      let payload = {
        page: this.currentPage,
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        contactFilterActive: this.contactFilterActive,
        filterByContactType: this.filterByContactType,
      };
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch(
          "ALL_VERIFICATION_DOCUMENTS",
          payload
        );
        if (response.status) {
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
